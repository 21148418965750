import Cookies from 'js-cookie'

export function setMemberRatesCookie(memberStatus: boolean) {
  Cookies.set('member', memberStatus, { path: '/' })
}

export function getMemberRatesCookie() {
  const memberCookie = Cookies.get('member')
  return memberCookie == 'true' ? true : false
}
